/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"

import "./src/styles/semantic-reset.css"
import "./src/styles/global.css"
import "./src/styles/custom.css"

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LfkX7YZAAAAAO_7ZG5q7oxynG0h62q_eVnN3etg"
      language="it"
    >
      {element}
    </GoogleReCaptchaProvider>
  )
}
