module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://ecb77f00f9964d0c8bf2c99510a93566@o298563.ingest.sentry.io/5378159","environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/vercel/a19199b/src/intl","languages":["en","it"],"defaultLanguage":"it","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Time2Client","short_name":"Time2Client","start_url":"/","background_color":"#ffffff","theme_color":"#1f363d","display":"minimal-ui","icon":"src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"e9ce409467cbcd2be27b0ba7ec782a38"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
